//funzioni per accesso con gplus
var gplus_clicked = false;
var visible_actions = "http://schemas.google.com/AddActivity";
var clientid_ = "610851596756-jeai0mj871b0b1cge2up28etfdsl2vqn.apps.googleusercontent.com";
//var scope_ = "https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/userinfo.email ";
var scope_ = "https://www.googleapis.com/auth/plus.login email profile";
var cookiepolicy_url = 'single_host_origin';
var sign_options = {
    clientid : clientid_,
    client_id : clientid_,
    accesstype : "offline",
    //callback : "onSignInCallback",
    onsuccess: "onSignIn",
    onfailure: "onSignInFailure",
    scope : scope_,
    cookiepolicy : cookiepolicy_url,
    requestvisibleactions : visible_actions
};
var link_options = {
    //clientid : clientid_,
    client_id : clientid_,
    accesstype : "offline", 
    //callback : "onLinkCallback",
    onsuccess:"onSignIn",
    onfailure:"onSignInFailure",
    scope : scope_, 
    cookiepolicy : cookiepolicy_url,
    requestvisibleactions : visible_actions
};

function normalize_pathname(pathname) {
    var thingpath = '/things';
    if (pathname.substring(0, thingpath.length) === thingpath) {
        var words = pathname.split('/');
        pathname = words.slice(0, 3).join('/');
    }
    return pathname;
}
function attachSignin(element) { 
    auth2.attachClickHandler(element, {},onSignIn,onSignInFailure);
}
function onSignIn(googleUser) {
    // Autorizzazione riuscita - Nascondo il pulsante di accesso  
    $.googlePlusLogin = googleUser.getAuthResponse().id_token;           
    console.log('G+ - Login '); 
    if($.userLogin) {
        //sessione attiva..gia loggato su gamestorm 
        var profile = googleUser.getBasicProfile();  
        console.log('G+ - user logged: ' + profile.getName() + ' - ' + profile.getEmail()); 
       /* va in 500 :(
        var moment = {
     'type': 'http://schemas.google.com/AddActivity',
     'object': {
       'description': 'https://developers.google.com/+/web/snippet/examples/widget'
     },
     'target': {
       'description': 'https://developers.google.com/+/web/snippet/examples/widget'
     }};
        var request = gapi.client.request({
            'path': 'plus/v1/people/me/moments/vault',
            'method': 'POST',
            'body': JSON.stringify(moment)
          });
        request.execute(function(result) {
          console.log(result);
        });*/
        /*gapi.client.METHOD_NAME(PARAMETERS_OBJECT).
  then(onFulfilled, onRejected);*/
    }
    else {
        //non sono loggato...faccio login via gplus!
        $('#signinButton').hide();
        $.userAjaxCall('loginGplus');
    }  
}
function onSignInFailure() {
    if (window.___gcfg.isSignedOut) {
        console.log('window.___gcfg.isSignedOut'); 
        var auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(function () {
            console.log('G+ - Logout ');
        });
        return;
    } 
}
function onSignInCallback(authResult) {
    if (authResult.error == 'access_denied' || authResult.error == 'user_signed_out')  {
        console.log('G+ - error: ' + authResult['error']);
        return false;
    }
    if (window.___gcfg.isSignedOut) {
        console.log('window.___gcfg.isSignedOut');
        if (gapi.auth && gapi.auth.getToken() != null) {
            console.log('G+ - signOut' );
            gapi.auth.signOut();
        };
        return;
    } 
    if (typeof authResult == "undefined" || typeof authResult.code == "undefined" && typeof authResult.access_token == "undefined") {
        console.log('G+ - error: no response' );
        if (gplus_clicked) { 
            //casistica non prevista
        }
        return;
    } 
    //console.debug(authResult);
    // Autorizzazione riuscita - Nascondo il pulsante di accesso 
    $.googlePlusLogin = authResult['access_token'];           
    console.log('G+ - Login '); 
    if($.userLogin) {
        //sessione attiva..gia loggato su gamestorm 
    }
    else {
        //non sono loggato...faccio login via gplus!
        $('#signinButton').hide();
        $.userAjaxCall('loginGplus');
    }  
};  
function initialize_google() {
    if (typeof gapi == "undefined" || typeof $ == "undefined" ) {
        setTimeout(initialize_google, 50);
        return;
    } 
    if($.userLogin) { 
        //è hidden usato x autologin .. nn serve rendergrafico custom quindi uso comando base 
        gapi.signin2.render('gplus-login',sign_options); 
    } 
    else { 
        gapi.load('auth2', function(){
            auth2 = gapi.auth2.init(sign_options);
            // Retrieve the singleton for the GoogleAuth library and set up the client.
            attachSignin('gplus-login');
            attachSignin('gplus-login-mobile-landscape');
            attachSignin('gplus-login-mobile-portrait');
            //check se sono ancora loggato su google.. 
            var profile = auth2.currentUser.get().getBasicProfile();
            if(profile && profile !== null && profile !== undefined ) {
                console.log('G+ - wellcome back: ' + profile.getName() + ' - ' + profile.getEmail()); 
                //non sono loggato...faccio login via gplus!
                $('#signinButton').hide();
                $.userAjaxCall('loginGplus');
            } 
        });
        
        $(".gplus-login").on('click', function() {
           gplus_clicked = true;
           window.___gcfg.isSignedOut = false;
        });
    } 
} 
function disconnectUserGplus( gsLogoutFunction ) {
    //gapi.auth.signOut();
    //console.log('G+ - Logout ');
    //$.googlePlusLogin = 0 ;
    var auth2 = gapi.auth2.getAuthInstance();
    /*auth2.signOut()*/auth2.disconnect().then(function () {
        console.log('G+ - Logout ');
        if(gsLogoutFunction && typeof(gsLogoutFunction) === "function" ) {
            gsLogoutFunction();
        }
        $.googlePlusLogin = 0 ;
    });
}
//inizializzazione pulsanti social di gplus ( +1 , condividi , follow user , ecc )
function render_google_buttons() {
    if (typeof gapi == "undefined" || typeof $ == "undefined" ) {
        setTimeout(render_google_buttons, 50);
        return;
    }
    gapi.plusone.go("gs-main-row");
    gapi.plus.go("shareGplusContainer");
    //gapi.person.go();
    gapi.plusone.go("gs-row-footer");
}


/*  
//funzioni x scrivere attivita fatte su gamestorm sul profilo gplus
function writeAddActivity(){
    var payload = {
        "type":"http://schemas.google.com/AddActivity"
    };
    payload.target = {
        'url' : 'http://www.gamestorm.it',
        "description" : "test",
        "name":"Gamestorm.it"
    }; 
    gapi.client.plus.moments.insert(
      {  'userId' : 'me',
         'collection' : 'vault',
         'resource' : payload
      }).execute(function(result){
          console.log(result);
      });
}

*/
//funzioni per accesso con facebook
function statusChangeCallback(response) {
    console.log('FB statusChangeCallback');
    console.log(response);
    // The response object is returned with a status field that lets the
    // app know the current login status of the person.
    // Full docs on the response object can be found in the documentation
    // for FB.getLoginStatus().
    if (response.status === 'connected') {
        // Logged into your app and Facebook.
        $.facebookLogin = 1 ;
        if($.userLogin) {}
        else {
            //non sono loggato...faccio login!
            $.userAjaxCall('loginFb');
        } 
    } 
    else if (response.status === 'not_authorized') { } 
    else { }
}
function checkLoginState() {
    // This function is called when someone finishes with the Login
    // Button.  See the onlogin handler attached to it in the sample
    // code below.
    FB.getLoginStatus(function(response) {
      statusChangeCallback(response);
    });
}
window.fbAsyncInit = function() { 
    FB.init({
        appId      : '333336810061886',
        cookie     : true,  // enable cookies to allow the server to access 
        // the session
        xfbml      : true,  // parse social plugins on this page
        version    : 'v2.1' // use version 2.1
  });
  /////FB.login(function(){}, {scope: 'publish_actions'});
  // Now that we've initialized the JavaScript SDK, we call 
  // FB.getLoginStatus().  This function gets the state of the
  // person visiting this page and can return one of three states to
  // the callback you provide.  They can be:
  //
  // 1. Logged into your app ('connected')
  // 2. Logged into Facebook, but not your app ('not_authorized')
  // 3. Not logged into Facebook and can't tell if they are logged into
  //    your app or not.
  //
  // These three cases are handled in the callback function.

  FB.getLoginStatus(function(response) {
    statusChangeCallback(response);
  });
};
function disconnectUserFB(gsLogoutFunction) {
    FB.logout(function(response) {
        $.facebookLogin= 0; 
        console.log('FB - Logout ');
        if(gsLogoutFunction && typeof(gsLogoutFunction) === "function" ) {
            gsLogoutFunction();
        }
    });
} 
//imposto click sui pulsanti di accesso via social
/*document.getElementById("fb-login").onclick = function(e) {
    //e.preventDefault();
    FB.login(checkLoginState, {scope:'public_profile,email'});
    return false;
};*/
/*$('.fb-login').on('click', function(e) {
    //e.preventDefault();
    
    FB.login(checkLoginState, {scope:'public_profile,email'});
    return false;
});*/
/*document.getElementById("gplus-login").onclick = function(e) {
    //e.preventDefault(); 
    return false;
};*/
/*$('.gplus-login').on('click', function(e) {
    //e.preventDefault();
    return false;
});*/